<template>
  <form @submit.prevent="submit">
    <div class="grid grid-cols-2 gap-4">
      <form-input
        name="first_name"
        :form="form"
        v-model="form.data.first_name.value"
      >
        First name
      </form-input>
      <form-input
        name="last_name"
        :form="form"
        v-model="form.data.last_name.value"
      >
        Last name
      </form-input>
    </div>
    <form-input
      name="email"
      :form="form"
      v-model="form.data.email.value"
      :disabled="true"
    >
      Email Address
    </form-input>
    <form-input name="mobile" :form="form" v-model="form.data.mobile.value">
      Mobile
    </form-input>
    <form-input
      type="select"
      :form="form"
      name="gender"
      :options="genderOptions"
      :defaultValue="`${$filters.sentenceCase(form.data.gender.value) || ''}`"
      v-model="form.data.gender.value"
    >
      Select Gender
    </form-input>
    <form-input name="address" :form="form" v-model="form.data.address.value">
      Home Address
    </form-input>
    <form-input v-model="bvn" :disabled="true">BVN</form-input>

    <div class="mt-8">
      <button type="submit" class="btn btn-primary" :disabled="form.loading">
        <loader v-if="form.loading" />
        <span v-else>Submit</span>
      </button>
    </div>
  </form>
</template>
<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  SetupContext,
  ref,
} from 'vue';
import resource from '@/modules/resource';
import validation from '@/modules/validation';
import sessionModule from '@/modules/auth/sessionModule';
import filters from '@/helpers/filters';
import { updateDetails } from '@/modules/requests';
import { AxiosError, AxiosResponse } from 'axios';
import { useStore } from 'vuex';

export default defineComponent({
  emits: ['success'],
  setup: (_props, { emit }: SetupContext) => {
    const { basicForm, getFormData } = resource();
    const { validateForm } = validation();
    const { mapProfileData, user, genderOptions } = sessionModule();

    const { dispatch } = useStore();

    const { truncate } = filters;

    const data = reactive({
      form: basicForm([
        {
          name: 'first_name',
          rules: 'required',
        },
        {
          name: 'last_name',
          rules: 'required',
        },
        {
          name: 'email',
          rules: 'required|email',
        },
        {
          name: 'mobile',
          rules: 'required|phone',
        },
        {
          name: 'gender',
          rules: 'required',
        },
        {
          name: 'address',
          rules: 'required',
        },
      ]),
    });

    onMounted(() => mapProfileData(data.form));

    const bvn = ref<string>(truncate(user?.value?.profile?.bvn, 5));

    const submit = async (): Promise<void | boolean> => {
      if (!validateForm(data.form)) return false;

      data.form.loading = true;
      await updateDetails(getFormData(data.form))
        .then((response: AxiosResponse) => {
          dispatch('updateUser', response?.data).then(() => emit('success'));
        })
        .catch((error: AxiosError) => console.log(error))
        .finally(() => (data.form.loading = false));
    };

    return { ...toRefs(data), submit, genderOptions, bvn };
  },
});
</script>
