
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  SetupContext,
  ref,
} from 'vue';
import resource from '@/modules/resource';
import validation from '@/modules/validation';
import sessionModule from '@/modules/auth/sessionModule';
import filters from '@/helpers/filters';
import { updateDetails } from '@/modules/requests';
import { AxiosError, AxiosResponse } from 'axios';
import { useStore } from 'vuex';

export default defineComponent({
  emits: ['success'],
  setup: (_props, { emit }: SetupContext) => {
    const { basicForm, getFormData } = resource();
    const { validateForm } = validation();
    const { mapProfileData, user, genderOptions } = sessionModule();

    const { dispatch } = useStore();

    const { truncate } = filters;

    const data = reactive({
      form: basicForm([
        {
          name: 'first_name',
          rules: 'required',
        },
        {
          name: 'last_name',
          rules: 'required',
        },
        {
          name: 'email',
          rules: 'required|email',
        },
        {
          name: 'mobile',
          rules: 'required|phone',
        },
        {
          name: 'gender',
          rules: 'required',
        },
        {
          name: 'address',
          rules: 'required',
        },
      ]),
    });

    onMounted(() => mapProfileData(data.form));

    const bvn = ref<string>(truncate(user?.value?.profile?.bvn, 5));

    const submit = async (): Promise<void | boolean> => {
      if (!validateForm(data.form)) return false;

      data.form.loading = true;
      await updateDetails(getFormData(data.form))
        .then((response: AxiosResponse) => {
          dispatch('updateUser', response?.data).then(() => emit('success'));
        })
        .catch((error: AxiosError) => console.log(error))
        .finally(() => (data.form.loading = false));
    };

    return { ...toRefs(data), submit, genderOptions, bvn };
  },
});
